import React, { useState, useEffect } from 'react';
import { FaBell } from 'react-icons/fa';
import Modal from 'react-modal';
import axios from 'axios';

const Alerts = ({ cliente, user_id }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        Modal.setAppElement('#root'); // Set the app element for accessibility
        fetchAlerts();

        // Set up interval to refresh alerts every 30 seconds
        const interval = setInterval(() => {
            fetchAlerts();
        }, 60000);

        // Clean up interval on component unmount
        return () => clearInterval(interval);
    }, []);

    const fetchAlerts = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL + 'alerts.php', {
                params: { cliente, user_id }
            });
            setMessages(response.data);
        } catch (error) {
            console.error('Error fetching alerts:', error);
        }
    };

    const unreadMessages = messages.filter(message => !message.read).length;

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const markAsRead = async (id) => {
        try {
            await axios.post(process.env.REACT_APP_URL + 'alerts_readed.php', { 
                ids: [id],
                cliente,
                user_id
            });
            setMessages(messages.map(message => 
                message.id === id ? { ...message, read: true } : message
            ));
        } catch (error) {
            console.error('Error marking alert as read:', error);
        }
    };

    const markAllAsRead = async () => {
        const ids = messages.filter(message => !message.read).map(message => message.id);
        try {
            await axios.post(process.env.REACT_APP_URL + 'alerts_readed.php', { 
                ids,
                cliente,
                user_id
            });
            setMessages(messages.map(message => ({ ...message, read: true })));
        } catch (error) {
            console.error('Error marking all alerts as read:', error);
        }
    };

    return (
        <div className='alerts'>
            <div className="bell-container" onClick={openModal}>
                <FaBell className={`bell-icon ${unreadMessages > 0 ? 'animated' : ''}`} />
                {unreadMessages > 0 && <span className="badge">{unreadMessages}</span>}
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Alertas"
                className="modalalerts"
                overlayClassName="overlayalerts"
            >
                <h2>Alerts</h2>
                <button onClick={markAllAsRead}>Marcar todos como leídos</button>
                <ul>
                    {messages.map(message => (
                        <li key={message.id} className={message.read ? 'read' : 'unread'}>
                            {message.mensaje}
                            {!message.read && (
                                <button onClick={() => markAsRead(message.id)}>Leído</button>
                            )}
                        </li>
                    ))}
                </ul>
                <button onClick={closeModal}>Cerrar</button>
            </Modal>
        </div>
    );
};

export default Alerts;